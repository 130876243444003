<template>
  <div class="content">
    <div class="content__title content__title--party content__title--start">
      <router-link
        :to="{ name: 'events-manage' }"
        class="button button--light button--no-indent button--small"
      >
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
        </svg>
      </router-link>
      <h1 class="title title--big title--theme">Добавление мероприятия</h1>
    </div>
    <form @submit.prevent="onCheckForm">
      <app-grid>
        <template #item-1>
          <h2 class="content__title title">Основная информация</h2>
          <app-form-group label="Обложка" required>
            <app-cropper
              v-model="form.cropped_data"
              :aspect-ratio="16 / 9"
              ref="cropper"
              :webcam="false"
            />
            <template #error>
              <div v-if="$v.form.cropped_data.$dirty && !$v.form.cropped_data.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="name" label="Название мероприятия" required>
            <app-input
              v-model.trim="form.name"
              id="name"
              placeholder="Введите название мероприятия"
              :class="{ 'input--error': $v.form.name.$error }"
              @change.native="$v.form.name.$touch()"
            />
            <template #error>
              <div v-if="$v.form.name.$dirty && !$v.form.name.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="organizer" label="Организатор мероприятия" required>
            <app-input
              v-model.trim="form.organizer"
              id="organizer"
              placeholder="Введите организатора"
              :class="{ 'input--error': $v.form.organizer.$error }"
              @change.native="$v.form.organizer.$touch()"
            />
            <template #error>
              <div v-if="$v.form.organizer.$dirty && !$v.form.organizer.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="site" label="Сайт организатора мероприятия">
            <app-input
              v-model.trim="form.site"
              id="site"
              type="url"
              placeholder="https://www.site.com"
              :class="{ 'input--error': $v.form.site.$error }"
              @change.native="$v.form.site.$touch()"
            />
            <template #error>
              <div v-if="$v.form.site.$dirty && !$v.form.site.url">Введите правильный формат ссылки</div>
            </template>
          </app-form-group>
          <app-form-group label-for="desc" label="Описание мероприятия" required>
            <vue-editor
              v-model="form.desc"
              ref="qEditor"
              :editor-toolbar="customToolbar"
              class="editor"
              placeholder="Опишите свое мероприятие"
              @paste.native="onEditorPast"
            />
            <template #error>
              <div v-if="$v.form.desc.$dirty && !$v.form.desc.required">Обязательное поле</div>
            </template>
          </app-form-group>
        </template>
        <template #item-2>
          <app-form-group label="Тип мероприятия" required>
            <v-select
              v-model="form.type"
              :reduce="type => type.id"
              :options="type_options"
              :filterable="false"
              :clearable="true"
              :searchable="false"
              label="id"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.type.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
            </v-select>
            <template #error>
              <div v-if="$v.form.type.$dirty && !$v.form.type.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Формат участия" required>
            <v-select
              v-model="form.participation_form"
              :reduce="format => format.id"
              :options="format_options"
              :filterable="false"
              :clearable="true"
              :searchable="false"
              label="id"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.participation_form.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
            </v-select>
            <template #error>
              <div v-if="$v.form.participation_form.$dirty && !$v.form.participation_form.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Тип участия" required>
            <app-cells position="start" :indent="false">
              <app-radio
                v-model.number="form.part_type"
                label="Одна заявка на направление"
                value="one_on_shift"
                name="part_type"
                :checked="form.part_type"
              />
              <app-radio
                v-model.number="form.part_type"
                label="Одна заявка на мероприятие"
                value="one_on_event"
                name="part_type"
                :checked="form.part_type"
              />
            </app-cells>
          </app-form-group>
          <app-form-group label-for="region" label="Регион проведения">
            <v-select
              v-model="form.region"
              :options="region_options"
              :filterable="false"
              :clearable="false"
              @search="onRegionSearch"
              @input="onSetRegion"
              label="address"
              class="select"
              placeholder="Выберите регион"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </template>
              <template #no-options="{ search, searching }">
                <div v-if="searching" class="select__noty">
                  По вашему запросу <em>{{ search }}</em> ничего не найдено.
                </div>
                <div v-else class="select__noty">
                  Для поиска региона в справочнике, введите адрес в формате: <span>Ставрополь, ул. Пушкина, д 65в, кв. 1</span> и выберите значение из выпадающего списка.
                </div>
              </template>
              <template slot="option" slot-scope="option">
                <div class="select__item">{{ option.address }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="d-center">{{ option.address }}</div>
              </template>
            </v-select>
          </app-form-group>
          <app-form-group label="Документы" required>
            <AppMultiUploader
              v-model="form.docs"
              :limit="10"
            />
            <template #error>
              <div v-if="$v.form.docs.$dirty && !$v.form.docs.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-cells position="between">
            <h2 class="title">Контакты</h2>
            <button @click="onCloneContacts" class="link" type="button">Добавить еще</button>
          </app-cells>
          <app-form-group v-for="(item, index) in form.contacts" :key="index" :label="index > 0 ? `Контактные данные №${index + 1}` : ''">
            <template #additional>
              <app-button v-if="index > 0" @click="onDeleteContacts(index)" size="link" class="btn--error" type="button">Удалить</app-button>
            </template>
            <app-form-group :label-for="`fio_${index}`" label="ФИО" required>
              <app-input
                v-model.trim="item.fio"
                :id="`fio_${index}`"
                placeholder="Фамилия Имя Отчество"
                :class="{ 'input--error': $v.form.contacts.$each[index].fio.$error }"
                @change.native="$v.form.contacts.$each[index].fio.$touch()"
              />
              <template #error>
                <div v-if="$v.form.contacts.$each[index].fio.$dirty && !$v.form.contacts.$each[index].fio.required">
                  Обязательное поле
                </div>
                <div v-if="$v.form.contacts.$each[index].fio.$dirty && !$v.form.contacts.$each[index].fio.ruAlpha">
                  Принимаются только буквы русского алфавита
                </div>
              </template>
            </app-form-group>
            <app-form-group :label-for="`email_${index}`" label="Адрес электронной почты" required>
              <app-input
                v-model.trim="item.email"
                :id="`email_${index}`"
                placeholder="Введите адрес электронной почты"
                :class="{ 'input--error': $v.form.contacts.$each[index].email.$error }"
                @change.native="$v.form.contacts.$each[index].email.$touch()"
              />
              <template #error>
                <div v-if="$v.form.contacts.$each[index].email.$dirty && !$v.form.contacts.$each[index].email.required">
                  Обязательное поле
                </div>
                <div v-if="$v.form.contacts.$each[index].email.$dirty && !$v.form.contacts.$each[index].email.email">
                  Введите правильный формат почты
                </div>
              </template>
            </app-form-group>
            <app-form-group label="Телефон">
              <app-phone
                v-model="item.phone"
                placeholder="Введите номер телефона"
                :error="$v.form.contacts.$each[index].phone.$dirty && item.phone === 0"
                @change.native="$v.form.contacts.$each[index].phone.$touch()"
                @paste.native.prevent
              />
              <template #error>
                <div v-if="$v.form.contacts.$each[index].phone.$dirty && (item.phone === 0)">
                  Неправильный формат номера
                </div>
              </template>
            </app-form-group>
          </app-form-group>
        </template>
      </app-grid>
      <AppCells>
        <AppButton :disabled="$v.form.$error" ref="submit">Добавить</AppButton>
      </AppCells>
    </form>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { required, helpers, email, url } from 'vuelidate/lib/validators'
import {debounce} from 'lodash'
const ruAlpha = helpers.regex('ruAlpha', /[а-яёА-ЯЁ]/i)

export default {
  name: 'EventCreate',
  components: { VueEditor },
  data() {
    return {
      form: {
        contacts: [ { fio: '', email: '', phone: '' } ],
        docs: [],
        image: '',
        cropped_data: {},
        coords: {},
        part_type: 'one_on_shift'
      },
      type_options: [],
      format_options: [],
      fileUrl: `${process.env.VUE_APP_API_URL}files/`,
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'], ['clean']
      ],
      region_options: []
    }
  },
  validations: {
    form: {
      cropped_data: { required },
      name: { required },
      organizer: { required },
      site: { url },
      desc: { required },
      type: { required },
      participation_form: { required },
      docs: { required },
      contacts: {
        $each: {
          fio: { ruAlpha, required },
          email: { email, required },
          phone: {},
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch('dict/GET_EVENT_TYPE')
      .then(response => { this.type_options = response.data })

    this.$store.dispatch('dict/GET_EVENT_FORMAT')
      .then(response => { this.format_options = response.data })
  },
  methods: {
    onEditorPast() {
      this.$refs.qEditor.quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        delta.ops = delta.ops.map(op => {
          return {
            insert: op.insert
          }
        })
        return delta
      })
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.$refs.cropper.sendCrop().then(() => {
          this.form.image = this.form.cropped_data.photo
          this.form.coords = this.form.cropped_data.coords
          this.form.cropped_data = {}
          this.sendForm()
        })
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      this.$store.dispatch('events/POST_DATA', this.normalizeForm())
        .then(() => {
          this.$refs.submit.preload = false
          this.$router.push({ name: 'events-manage' })
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Мероприятие успешно создано.'
          })
        })
        .catch(error => {
          this.$refs.submit.preload = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
    normalizeForm() {
      const { ...normalizedForm } = this.form

      normalizedForm.docs = normalizedForm.docs.map(item => item.id)
      return normalizedForm
    },
    onCloneContacts() {
      this.form.contacts.push({ fio: '', email: '', phone: '' })
    },
    onDeleteContacts(index) {
      this.form.contacts.splice(index, 1)
    },
    onRegionSearch (search, loading) {
      loading(true)
      this.searchRegion(loading, search, this)
    },
    searchRegion: debounce((loading, search, vm) => {
      vm.$store.dispatch('search/GET_ADDRESS_REGION', {search}).then(response => {
        vm.region_options = response.data
        loading(false)
      })
    }, 350),
    onSetRegion() {
      this.form.region_text = this.form.region.region
    },
  }
}
</script>
